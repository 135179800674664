import { useWeb3Modal, useWeb3ModalState } from "@web3modal/wagmi/react";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAccount, useSwitchNetwork } from "wagmi";
import { StakingApp } from "../Context/StakingApp";
import { toast } from "react-toastify";
import { LinearProgress, linearProgressClasses, styled } from "@mui/material";

export default function Home() {
  const { open } = useWeb3Modal();
  const { address } = useAccount();
  const navigate = useNavigate();
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const { selectedNetworkId } = useWeb3ModalState();
  const { switchNetwork } = useSwitchNetwork();

  const {
    symbol,
    totalSaply,
    time,
    allowanceamount,
    approveusd,
    buy,
    buyamount,
    buyamount1,
    val1,
    softCap,
    limit,
    val2,
    value,
    setValue,
    buyrefaddress,
    refaddress,
    setRefaddress,
  } = useContext(StakingApp);

  useEffect(() => {
    setRefaddress(localStorage.getItem("refAdd"));
  }, []);

  console.log(refaddress, "refaddress");

  const urlParams = new URLSearchParams(window.location.search);
  const type = urlParams.get("referral");

  useEffect(() => {
    localStorage.setItem("refAdd", type);
  }, [type]);
  const onCopyLink = (id) => {
    navigator?.clipboard?.writeText(id);
  };

  const pvalue = ((softCap * 30000 * 10 ** 18) / (25 * 10 ** 28)) * 100;

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 15,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
    },
  }));

  const increment = () => {
    if (value < 100) {
      setValue(value + 1);
    } else {
      toast.warn("Value cannot be greater than 1 Eth");
    }
  };

  const decrement = () => {
    if (value > 1) {
      setValue(value - 1);
    } else {
      toast.warn("Value cannot be less than 0.01 Eth");
    }
  };

  const handleDate = (val) => {
    const date = new Date(val);
    const formattedDate = date.toLocaleDateString();

    return formattedDate;
  };
  const date = () => {
    return moment(handleDate(time)).format("MM,DD,YYYY");
  };
  const getTime = (dal) => {
    const times = Date.parse(dal) - Date.now();

    setDays(Math.floor(times / (1000 * 60 * 60 * 24)));
    setHours(Math.floor((times / (1000 * 60 * 60)) % 24));
    setMinutes(Math.floor((times / 1000 / 60) % 60));
    setSeconds(Math.floor((times / 1000) % 60));
  };

  useEffect(() => {
    const deadline = date();
    const interval = setInterval(() => getTime(deadline), 1000);
    return () => clearInterval(interval);
  }, [time != null]);
  return (
    <div>
      <main class="ln">
        <div class="ln-inner">
          <div class="ln-top">
            <div class="ln-content">
              <div class="ln-content-hamster">
                <div class="ln-content-hamster-image is-1">
                  <picture class="is-hamster-image is-level-6">
                    <source
                      srcset="assets/img/hamsters/6.avif"
                      type="image/avif"
                    />
                    <source
                      srcset="assets/img/hamsters/6.webp"
                      type="image/webp"
                    />
                    <img
                      class="img-responsive"
                      src="assets/img/hamsters/6.png"
                      alt="Hamster Coin"
                    />
                  </picture>
                </div>
                <div class="ln-content-hamster-image is-2">
                  <picture class="is-hamster-image is-level-2">
                    <source
                      srcset="assets/img/hamsters/2.avif"
                      type="image/avif"
                    />
                    <source
                      srcset="assets/img/hamsters/2.webp"
                      type="image/webp"
                    />
                    <img
                      class="img-responsive"
                      src="assets/img/hamsters/2.png"
                      alt="Hamster Coin"
                    />
                  </picture>
                </div>
                <div class="ln-content-hamster-image is-3">
                  <picture class="is-hamster-image is-level-9">
                    <source
                      srcset="assets/img/hamsters/9.avif"
                      type="image/avif"
                    />
                    <source
                      srcset="assets/img/hamsters/9.webp"
                      type="image/webp"
                    />
                    <img
                      class="img-responsive"
                      src="assets/img/hamsters/9.png"
                      alt="Hamster Coin"
                    />
                  </picture>
                </div>
                <div class="ln-content-hamster-image is-4">
                  <picture class="is-hamster-image is-level-7">
                    <source
                      srcset="assets/img/hamsters/7.avif"
                      type="image/avif"
                    />
                    <source
                      srcset="assets/img/hamsters/7.webp"
                      type="image/webp"
                    />
                    <img
                      class="img-responsive"
                      src="assets/img/hamsters/7.png"
                      alt="Hamster Coin"
                    />
                  </picture>
                </div>
              </div>
              <h1>Unleash your inner CEO</h1>
              <div class="ln-content-exchanges">
                <div class="ln-content-exchange">
                  <div class="exchange-image is-binance">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 48 48"
                      fill="none"
                    >
                      <path
                        fill="#F3BA2F"
                        d="M14.678 20.17 24 10.849l9.326 9.326 5.424-5.424L24 0 9.254 14.746l5.424 5.424zm-3.83 3.829-5.424-5.424L0 23.999l5.424 5.424 5.424-5.424zm3.83 3.83L24 37.152l9.326-9.326 5.427 5.42-.003.004L24 47.999 9.254 33.254l-.008-.008 5.432-5.416zm27.898 1.596L48 24.001l-5.424-5.424-5.424 5.425 5.424 5.423z"
                      ></path>
                      <path
                        fill="#F3BA2F"
                        d="M29.501 23.997h.003L24 18.493l-4.068 4.067-.467.468-.964.964-.008.008.008.007 5.499 5.5 5.504-5.505.002-.002-.005-.003Z"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div class="ln-content-exchange">
                  <div class="exchange-image is-bybit">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 87 34"
                      fill="none"
                    >
                      <path
                        fill="#F7A600"
                        d="M62.008 25.357V3h4.494v22.357h-4.494Z"
                      ></path>
                      <path
                        fill="#fff"
                        d="M9.634 31.998H0V9.641h9.247c4.494 0 7.112 2.45 7.112 6.28 0 2.48-1.682 4.083-2.846 4.617 1.39.627 3.168 2.04 3.168 5.024 0 4.175-2.94 6.436-7.047 6.436Zm-.743-18.463H4.494v5.15H8.89c1.907 0 2.974-1.036 2.974-2.575 0-1.538-1.067-2.574-2.974-2.574Zm.29 9.075H4.495v5.496h4.688c2.037 0 3.005-1.256 3.005-2.764 0-1.507-.97-2.732-3.005-2.732ZM30.388 22.83v9.168h-4.462V22.83L19.007 9.641h4.882l4.3 9.012 4.235-9.012h4.881L30.388 22.83ZM50.046 31.998h-9.634V9.641h9.246c4.494 0 7.113 2.45 7.113 6.28 0 2.48-1.682 4.083-2.846 4.617 1.39.627 3.168 2.04 3.168 5.024 0 4.175-2.94 6.436-7.047 6.436Zm-.743-18.463h-4.397v5.15h4.397c1.907 0 2.974-1.036 2.974-2.575 0-1.538-1.067-2.574-2.974-2.574Zm.29 9.075h-4.688v5.496h4.688c2.037 0 3.006-1.256 3.006-2.764 0-1.507-.968-2.732-3.006-2.732ZM80.986 13.536V32h-4.494V13.535h-6.014V9.641H87v3.895h-6.014Z"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div class="ln-content-exchange">
                  <div class="exchange-image is-okx">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="2500"
                      height="702"
                      viewBox="0 -0.001 157.427 44.186"
                    >
                      <path
                        fill="#fff"
                        d="M46.17 0H1.05C.77 0 .503.103.306.288A.951.951 0 0 0 0 .982v42.22c0 .26.11.51.307.694s.464.288.742.288H46.17c.279 0 .545-.104.743-.288a.952.952 0 0 0 .307-.694V.982a.95.95 0 0 0-.307-.694A1.088 1.088 0 0 0 46.17 0zM31.48 28.474c0 .26-.11.51-.308.694a1.087 1.087 0 0 1-.741.288H16.789c-.278 0-.545-.104-.742-.288a.951.951 0 0 1-.307-.694V15.71c0-.26.11-.51.307-.694.197-.185.464-.288.742-.288h13.642c.278 0 .545.103.741.288a.95.95 0 0 1 .308.694zM140.63 14.73h-13.642c-.58 0-1.05.44-1.05.982v12.764c0 .542.47.982 1.05.982h13.641c.58 0 1.05-.44 1.05-.982V15.712c0-.543-.47-.982-1.05-.982zM124.893 0h-13.641c-.58 0-1.05.44-1.05.983v12.764c0 .542.47.982 1.05.982h13.64c.58 0 1.05-.44 1.05-.982V.983c0-.543-.47-.982-1.05-.982zm31.485 0h-13.642c-.579 0-1.049.44-1.049.983v12.764c0 .542.47.982 1.05.982h13.64c.58 0 1.05-.44 1.05-.982V.983c0-.543-.47-.982-1.05-.982zm-31.485 29.457h-13.641c-.58 0-1.05.44-1.05.982v12.765c0 .542.47.981 1.05.981h13.64c.58 0 1.05-.44 1.05-.981V30.439c0-.542-.47-.982-1.05-.982zm31.485 0h-13.642c-.579 0-1.049.44-1.049.982v12.765c0 .542.47.981 1.05.981h13.64c.58 0 1.05-.44 1.05-.981V30.439c0-.542-.47-.982-1.05-.982zM101.264 0h-13.64c-.58 0-1.05.44-1.05.983v12.764c0 .542.47.982 1.05.982h13.64c.58 0 1.05-.44 1.05-.982V.983c0-.543-.47-.982-1.05-.982zm0 29.457h-13.64c-.58 0-1.05.44-1.05.982v12.765c0 .542.47.981 1.05.981h13.64c.58 0 1.05-.44 1.05-.981V30.439c0-.542-.47-.982-1.05-.982zM86.57 15.699c0-.26-.112-.51-.308-.695a1.087 1.087 0 0 0-.742-.287H70.829V.982a.95.95 0 0 0-.307-.694A1.087 1.087 0 0 0 69.78 0H56.139c-.279 0-.546.103-.743.288a.951.951 0 0 0-.307.694V43.18c0 .26.11.51.307.694s.464.288.743.288h13.64c.279 0 .546-.104.743-.288s.307-.434.307-.694V29.445h14.69c.279 0 .546-.104.743-.288a.952.952 0 0 0 .307-.694z"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div class="ln-content-exchange">
                  <div class="exchange-image is-bingx">
                    <svg viewBox="0 0 90 90" xmlns="http://www.w3.org/2000/svg">
                      <defs>
                        <radialGradient
                          cx="50.004%"
                          cy="50%"
                          fx="50.004%"
                          fy="50%"
                          r="43.958%"
                          gradientTransform="matrix(.79688 0 0 1 .102 0)"
                          id="a"
                        >
                          <stop stop-color="#2A54FF" offset="0%"></stop>
                          <stop
                            stop-color="#2A54FF"
                            stop-opacity="0"
                            offset="100%"
                          ></stop>
                        </radialGradient>
                      </defs>
                      <g fill="none">
                        <path fill="#2A54FF" d="M0 0h90v90H0z"></path>
                        <path
                          d="M77 19a43.928 43.928 0 0 0-21.13 5.414 44.107 44.107 0 0 0-16.886 16.51 30.384 30.384 0 0 1-1.819 2.794c-.085.118-.168.234-.26.349-5.72 7.58-14.432 11.927-23.905 11.927V70a43.928 43.928 0 0 0 21.13-5.414 44.25 44.25 0 0 0 11.401-9.003 44.207 44.207 0 0 0 5.482-7.504l.104-.174a30.522 30.522 0 0 1 1.715-2.62c.085-.115.17-.234.262-.355C58.813 37.352 67.527 33.003 77 33.003V19Z"
                          fill="#FFF"
                        ></path>
                        <path
                          d="M64 0a43.928 43.928 0 0 0-21.13 5.414 44.107 44.107 0 0 0-16.886 16.51 30.384 30.384 0 0 1-1.819 2.794c-.085.118-.168.234-.26.349C18.186 32.647 9.474 36.994 0 36.994V51a43.928 43.928 0 0 0 21.13-5.414 44.25 44.25 0 0 0 11.401-9.003 44.207 44.207 0 0 0 5.482-7.504l.104-.174a30.522 30.522 0 0 1 1.715-2.62c.085-.115.17-.234.262-.355C45.813 18.352 54.527 14.003 64 14.003V0Z"
                          fill="url(#a)"
                          opacity=".2"
                          transform="translate(13 19)"
                        ></path>
                        <path
                          d="M77 55.994c-9.473 0-18.187-4.346-23.908-11.927-.086-.115-.175-.23-.26-.355a29.4 29.4 0 0 1-1.816-2.791 44.36 44.36 0 0 0-5.487-7.504 45.887 45.887 0 0 0-1.94-2.002 43.912 43.912 0 0 0-9.458-6.998A43.928 43.928 0 0 0 13 19.003v14.006c9.473 0 18.187 4.346 23.908 11.927.09.115.175.234.26.35a30.09 30.09 0 0 1 1.712 2.613l.11.183a44.393 44.393 0 0 0 5.482 7.501 44.62 44.62 0 0 0 1.939 2.002 44.118 44.118 0 0 0 9.458 6.998A43.93 43.93 0 0 0 77 69.997V55.994Z"
                          fill="#FFF"
                        ></path>
                      </g>
                    </svg>
                  </div>
                </div>
                <div class="ln-content-exchange">
                  <div class="exchange-image is-htx">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 525 203"
                      fill="none"
                    >
                      <g clip-path="url(#htx)">
                        <path
                          fill="#fff"
                          d="M87.082 62.636C87.684 32.826 70.804 6.85 62.012.607c-.04-.04-.823-.462-.763.703 0 .02-.02.02-.02.04-.903 56.308-29.847 71.484-45.563 92.12-34.925 45.889-5.781 98.243 31.974 108.159.643.161 2.389.683 5.7 1.365 1.727.362 2.229-1.104.964-3.292-4.516-7.849-12.545-21.098-14.13-38.121-3.613-39.526 46.205-64.257 46.908-98.945Z"
                        ></path>
                        <path
                          fill="#008CD6"
                          d="M106.11 81.727c-.301-.221-.722-.201-.762.18-.803 7.167-8.21 22.022-17.965 35.913-32.838 46.833-16.298 68.071-3.452 82.806 2.368 2.73 3.552 2.128 4.797.181 1.164-1.847 2.93-4.196 10.518-7.789 1.184-.562 29.847-15.778 32.958-50.426 2.991-33.524-18.507-54.723-26.094-60.865Z"
                        ></path>
                        <path
                          fill="#fff"
                          d="M276.622 73.195V196.43h-26.435v-49.783h-50.802v49.783h-27.097V73.195h27.097v49.784h50.802V73.195h26.435ZM398.84 73.195v24.37h-37.956v98.845h-27.197V97.565h-37.274v-24.37H398.84Z"
                        ></path>
                        <path
                          fill="#fff"
                          fill-rule="evenodd"
                          d="m495.105 196.551-31.072-42.879-30.83 42.698-30.148.04 45.704-62.912-43.135-60.303h30.128l89.36 123.356h-30.007Z"
                          clip-rule="evenodd"
                        ></path>
                        <path
                          fill="#008CD6"
                          d="m472.483 100.777 14.914 20.717 35.327-48.319h-29.968l-20.273 27.602Z"
                        ></path>
                      </g>
                      <defs>
                        <clipPath id="htx">
                          <path fill="#fff" d="M0 0h525v203H0z"></path>
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
                <div class="ln-content-exchange">
                  <div class="exchange-image is-kucoin">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      //   xml:space="preserve"
                      viewBox="0 0 2500 2500"
                    >
                      <path fill="none" d="M0 0h2500v2500H0z"></path>
                      <path
                        fill="#23af91"
                        fill-rule="evenodd"
                        d="m830.3 1250.3 740.5 740.6 467.4-467.4c83.6-75.2 211.4-71.9 290.9 7.6s82.9 207.4 7.6 290.9l-616.8 616.9c-82.9 81.5-215.7 81.5-298.6 0l-889.8-890.1v529c0 116.8-94.6 211.4-211.4 211.4-116.8 0-211.4-94.6-211.4-211.4V422c0-116.7 94.6-211.4 211.4-211.4 116.8 0 211.4 94.7 211.4 211.4v529l889.7-889.9c82.8-81.5 215.8-81.5 298.6 0L2337 677.9c75.2 83.6 71.9 211.4-7.6 290.9s-207.4 82.9-290.9 7.6L1571.1 509l-740.8 741.3zm740.8-211.7c-85.6 0-162.8 51.5-195.6 130.6-32.8 79.1-14.7 170.1 45.8 230.7 60.5 60.6 151.6 78.7 230.7 46 79.1-32.8 130.7-109.9 130.7-195.6 0-56.1-22.2-109.9-61.9-149.7-39.6-39.7-93.5-62-149.6-62h-.1z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div class="ln-content-exchange">
                  <div class="exchange-image is-gate_io">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      //   xml:space="preserve"
                      viewBox="0 0 2500 2500"
                    >
                      <path fill="none" d="M0 0h2500v2500H0z"></path>
                      <g fill-rule="evenodd" clip-rule="evenodd">
                        <path
                          fill="#2354e6"
                          d="M1250 1937.5c-379.7 0-687.5-307.8-687.5-687.5S870.3 562.5 1250 562.5V0C559.6 0 0 559.6 0 1250c0 690.3 559.6 1250 1250 1250 690.3 0 1250-559.6 1250-1250h-562.5c0 379.7-307.8 687.5-687.5 687.5z"
                        ></path>
                        <path
                          fill="#17e6a1"
                          d="M1250 1250h687.5V562.5H1250z"
                        ></path>
                      </g>
                    </svg>
                  </div>
                </div>
                <div class="ln-content-exchange">
                  <div class="exchange-image is-mexc">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      //   xml:space="preserve"
                      viewBox="0 0 2500 2500"
                    >
                      <path fill="none" d="M0 0h2500v2500H0z"></path>
                      <path
                        fill="#3156aa"
                        d="m2459.7 1566.6-540.6-937.7c-118.5-195.5-407.5-197.5-521.9 8.3l-567.6 975.2c-106 178.8 25 403.3 237.1 403.3H2204c214.1 0 374.2-230.8 255.7-449.1z"
                      ></path>
                      <path
                        fill="#1972e2"
                        d="m1680 1639.4-33.3-58.2c-31.2-54.1-99.8-170.5-99.8-170.5l-457.4-794.3C971 439.7 690.3 425.1 571.8 647.6L39.5 1568.7c-110.2 193.4 20.8 444.9 259.9 447h1900.4c-293.1 2.1-386.7-149.7-519.8-376.3z"
                      ></path>
                      <linearGradient
                        id="mexc"
                        x1="703.637"
                        x2="1935.647"
                        y1="1211.657"
                        y2="727.227"
                        gradientTransform="matrix(1 0 0 -1 0 2497.89)"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop
                          offset="0"
                          stop-color="#264ca2"
                          stop-opacity="0"
                        ></stop>
                        <stop offset="1" stop-color="#234588"></stop>
                      </linearGradient>
                      <path
                        fill="url(#mexc)"
                        d="m1680.1 1639.4-33.3-58.2c-31.2-54.1-99.8-170.5-99.8-170.5l-295.3-519.8-424.2 723.6c-106 178.8 25 403.4 237 403.4h1135.3c-295.2-2.2-386.7-151.9-519.7-378.5z"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div class="ln-content-exchange">
                  <div class="exchange-image is-bitget">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      //   xml:space="preserve"
                      x="0"
                      y="0"
                      version="1.1"
                      viewBox="0 0 2500 2500"
                    >
                      {/* <style>.st1{fill-rule:evenodd;clip-rule:evenodd;fill:#fff}</style> */}
                      <g>
                        <g>
                          <circle
                            cx="1250"
                            cy="1250"
                            r="1250"
                            fill="#1da2b4"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                          ></circle>
                          <path
                            d="M925 415c22-24 54-37 86-37h212c27 0 41 32 22 51L826 876h231l235 249H826l466 499h-282c-33 0-64-14-86-37l-473-506c-42-45-42-115 0-160l473-506h1z"
                            class="st1"
                          ></path>
                          <path
                            d="M1575 2085c-22 24-54 37-86 37h-212c-27 0-41-32-22-51l419-447h-231l-235-249h466l-466-499h282c33 0 64 14 86 37l473 506c42 45 42 115 0 160l-473 506h-1z"
                            class="st1"
                          ></path>
                        </g>
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
              <p>
                Make your way from the shaved hamster to the grandmaster CEO of
                the tier-1 crypto exchange
              </p>
              <p class="is-hidden-mobile">
                Buy upgrades, complete quests, invite friends and become the
                best
              </p>
              <div class="ln-content-button">
                <a class="button" href="#" target="_blank">
                  <span>Play now</span>
                </a>
              </div>
            </div>
          </div>

          <div class="presaleT">
            <h2>Pre-sale</h2>
            <div class="pesitem">
              <div className="mm22">
                <div className="innDiv">
                  <div className="salecover">
                    {" "}
                    <h4 class="text-center mt-2">Referral Rewards</h4>
                    <div class="bnbpay">
                      <div>
                        <label>Your ERC20 Address</label>
                        <input
                          value={window.location.host + "/?referral=" + address}
                          type="text"
                          class="form-control2"
                        />
                      </div>
                      <div class="text-center mt-2">
                        <button
                          onClick={() => {
                            onCopyLink(
                              window.location.host + "/?referral=" + address
                            );
                            toast.success("Copy referral");
                          }}
                          class="connectbtn"
                        >
                          Get Referral Link
                        </button>
                      </div>
                      <p class="text-center">Enjoy 10% of purchased tokens</p>
                    </div>
                    <h3>Tokenomics</h3>
                    <div class="text-center ttlsup">
                      <h4 class="txt-gold">Total Supply</h4>
                      <h4 class="fw-bold">100,000,000,000</h4>
                      <div class="imgtoken">
                        <img
                          src="assets/img/tokenomics.png"
                          alt="Tokenomics"
                          style={{ height: "490px" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="innDiv">
                  <div className="salecover">
                    <BorderLinearProgress
                      variant="determinate"
                      value={pvalue}
                    />
                    <h3 class="text-center">Hamster Coin</h3>
                    <h3 class="txt-gold text-center">Pre-Sale</h3>
                    <div class="ln-content-button">
                      <a class="button2" href="#" target="_blank">
                        <span>Tap To Earn</span>
                      </a>
                    </div>
                    <p class="text-center mt-2">PRESALE ENDS IN</p>
                    <div class="container">
                      <div id="countdown">
                        <ul>
                          <li>
                            <span id="days">{days}</span>days
                          </li>
                          <li>
                            <span id="hours">{hours}</span>Hours
                          </li>
                          <li>
                            <span id="minutes">{minutes}</span>Minutes
                          </li>
                          <li>
                            <span id="seconds">{seconds}</span>Seconds
                          </li>
                        </ul>
                      </div>
                      <div class="flex09">
                        <div>
                          <div class="lprice text-center">
                            <p class="txt-gold">Pre sale</p>
                            <h4>0.01Eth = 30000 Hkc</h4>
                          </div>
                        </div>
                        <div>
                          <div class="lprice text-center">
                            <p class="txt-gold">Tokens For Presale</p>
                            <h4>{limit?.toFixed(3)}</h4>
                          </div>
                        </div>
                      </div>

                      <div class="bnbpay my-2">
                        <div>
                          <label>Your Connected Address</label>
                          <input
                            type="text"
                            class="form-control2"
                            placeholder={
                              (address
                                ? address.slice(0, 32)
                                : "0x0000000000000000000000000") + "..."
                            }
                          />
                        </div>
                      </div>

                      <div class="bnbpay">
                        <div>
                          <label>ETH You Pay</label>
                          <div className="part3">
                            <div className="prt1">
                              <div
                                onClick={() => decrement()}
                                className="plusbox"
                              >
                                -
                              </div>
                            </div>
                            <div className="prt2">
                              <input
                                type="text"
                                className="form-control2"
                                value={`${0.01 * value} ETH`}
                              />
                            </div>{" "}
                            <div
                              onClick={() => increment()}
                              className="plusbox"
                            >
                              +
                            </div>
                          </div>
                          {/* <input
                            type="text"
                            class="form-control2"
                            placeholder="Minmum by 10 ETH"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                          /> */}
                        </div>
                        <div class="text-center mt-2">
                          {address ? (
                            selectedNetworkId === 11155111 ? (
                              refaddress === "null" ? (
                                <button
                                  type="submit"
                                  disabled={val2}
                                  class="connectbtn"
                                  style={{ width: "100%" }}
                                  onClick={() => buy()}
                                >
                                  Buy
                                </button>
                              ) : (
                                <button
                                  type="submit"
                                  disabled={val2}
                                  class="connectbtn"
                                  style={{ width: "100%" }}
                                  onClick={() => buyrefaddress()}
                                >
                                  Buy
                                </button>
                              )
                            ) : (
                              <button
                                type="submit"
                                class="connectbtn"
                                style={{ width: "100%" }}
                                onClick={() => switchNetwork?.(11155111)}
                              >
                                Switch Network To Eth
                              </button>
                            )
                          ) : (
                            <button
                              style={{ width: "100%" }}
                              onClick={() => open()}
                              class="connectbtn"
                            >
                              Connect Wallet
                            </button>
                          )}
                          {/* <button onClick={() => open()} class="connectbtn">
                            Disconnect Wallet
                          </button> */}
                        </div>
                      </div>
                      <div class="flex09">
                        {buyamount && (
                          <div>
                            <div class="lprice text-center">
                              <p class="txt-gold"> Your Received Coin</p>
                              <h4>
                                {Number(buyamount)?.toFixed(2)}
                                Hkc
                              </h4>
                            </div>
                          </div>
                        )}

                        {buyamount1 && (
                          <div>
                            <div class="lprice text-center">
                              <p class="txt-gold">Your Purchase</p>
                              <h4>{buyamount1} Eth</h4>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="ln-roadmap">
            <h2>Roadmap</h2>
            <div class="ln-roadmap-inner">
              <div class="ln-roadmap-item is-completed">
                <div class="ln-roadmap-title">
                  <p>March 2024</p>
                </div>
                <div class="ln-roadmap-content">
                  <ul>
                    <li>Basic game</li>
                    <li>Mining updates</li>
                    <li>Earn tasks</li>
                  </ul>
                </div>
              </div>
              <div class="ln-roadmap-item is-completed">
                <div class="ln-roadmap-title">
                  <p>April 2024</p>
                </div>
                <div class="ln-roadmap-content">
                  <ul>
                    <li>Referral system</li>
                    <li>LVL ratings</li>
                    <li>Daily rewards</li>
                  </ul>
                </div>
                <div class="ln-roadmap-hamster is-1">
                  <div class="ln-roadmap-hamster-bg">
                    <img
                      src="assets/img/hamster-bg-1.png"
                      loading="lazy"
                      alt="Hamster Coin"
                    />
                  </div>
                  <div class="ln-roadmap-hamster-image">
                    <picture class="is-hamster-image is-level-5">
                      <source
                        srcset="assets/img/hamsters/5.avif"
                        type="image/avif"
                      />
                      <source
                        srcset="assets/img/hamsters/5.webp"
                        type="image/webp"
                      />
                      <img
                        class="img-responsive"
                        src="assets/img/hamsters/5.png"
                        alt="Hamster Coin"
                      />
                    </picture>
                  </div>
                </div>
              </div>
              <div class="ln-roadmap-item is-completed">
                <div class="ln-roadmap-title">
                  <p>May 2024</p>
                </div>
                <div class="ln-roadmap-content">
                  <ul>
                    <li>Special cards</li>
                    <li>Daily Combo</li>
                    <li>«Partner» blockchain announcement</li>
                  </ul>
                </div>
              </div>
              <div class="ln-roadmap-item">
                <div class="ln-roadmap-title">
                  <p>June 2024</p>
                </div>
                <div class="ln-roadmap-content">
                  <ul>
                    <li>On-chain infrastructure development</li>
                    <li>Wallet in-game implementation</li>
                    <li>Web 3 pre-listing Quest</li>
                  </ul>
                </div>
              </div>
              <div class="ln-roadmap-item">
                <div class="ln-roadmap-title">
                  <p>July 2024</p>
                </div>
                <div class="ln-roadmap-content">
                  <ul>
                    <li>TGE</li>
                    <li>Token in-game utility launch </li>
                  </ul>
                </div>
              </div>
              <div class="ln-roadmap-item">
                <div class="ln-roadmap-title">
                  <p>Q3 2024</p>
                </div>
                <div class="ln-roadmap-content">
                  <ul>
                    <li>Squad Coin</li>
                    <li>Characters and skins</li>
                    <li>Time limited events</li>
                    <li>Live events</li>
                  </ul>
                </div>
              </div>
              <div class="ln-roadmap-item">
                <div class="ln-roadmap-title">
                  <p>Q4 2024</p>
                </div>
                <div class="ln-roadmap-content">
                  <ul>
                    <li>
                      Many more, we’ll keep in secret not to spoil your
                      excitement
                    </li>
                  </ul>
                </div>
                <div class="ln-roadmap-hamster is-2">
                  <div class="ln-roadmap-hamster-bg">
                    <img
                      src="assets/img/hamster-bg-2.png"
                      loading="lazy"
                      alt="Hamster Coin"
                    />
                  </div>
                  <div class="ln-roadmap-hamster-image">
                    <picture class="is-hamster-image is-level-9">
                      <source
                        srcset="assets/img/hamsters/9.avif"
                        type="image/avif"
                      />
                      <source
                        srcset="assets/img/hamsters/9.webp"
                        type="image/webp"
                      />
                      <img
                        class="img-responsive"
                        src="assets/img/hamsters/9.png"
                        alt="Hamster Coin"
                      />
                    </picture>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="ln-footer">
            <div class="ln-footer-social">
              <a class="is-discord" href="#" target="_blank" title="Discord">
                <div class="icon">
                  <svg viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M27.7187 8.93244C25.9062 8.08442 23.9682 7.46812 21.942 7.11719C21.6932 7.56707 21.4025 8.17216 21.2021 8.65352C19.0482 8.32961 16.9142 8.32961 14.8 8.65352C14.5996 8.17216 14.3023 7.56707 14.0513 7.11719C12.0229 7.46812 10.0827 8.08669 8.27022 8.93693C4.61442 14.4613 3.62339 19.8485 4.1189 25.1592C6.54363 26.9699 8.89348 28.0699 11.2037 28.7897C11.7741 28.0047 12.2828 27.1701 12.721 26.2907C11.8864 25.9735 11.087 25.5821 10.3316 25.1277C10.532 24.9793 10.728 24.8241 10.9174 24.6644C15.5246 26.8193 20.5304 26.8193 25.0825 24.6644C25.2741 24.8241 25.4701 24.9793 25.6683 25.1277C24.9107 25.5843 24.1091 25.9757 23.2744 26.2929C23.7127 27.1701 24.2192 28.0069 24.7918 28.7919C27.1042 28.0721 29.4563 26.9722 31.881 25.1592C32.4624 19.0028 30.8878 13.6651 27.7187 8.93244ZM13.3487 21.8932C11.9657 21.8932 10.8315 20.602 10.8315 19.0298C10.8315 17.4575 11.9415 16.1641 13.3487 16.1641C14.756 16.1641 15.8902 17.4552 15.8659 19.0298C15.8681 20.602 14.756 21.8932 13.3487 21.8932ZM22.6512 21.8932C21.2682 21.8932 20.134 20.602 20.134 19.0298C20.134 17.4575 21.2439 16.1641 22.6512 16.1641C24.0584 16.1641 25.1926 17.4552 25.1684 19.0298C25.1684 20.602 24.0584 21.8932 22.6512 21.8932Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
              </a>
              <a
                class="is-telegram"
                href="#"
                target="_blank"
                title="Telegram Chat"
              >
                <div class="icon">
                  <svg
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M27.6132 4.873C27.6132 4.873 30.2032 3.863 29.9866 6.31567C29.9152 7.32567 29.2679 10.861 28.7639 14.6843L27.0372 26.011C27.0372 26.011 26.8932 27.6703 25.5979 27.959C24.3032 28.247 22.3606 26.949 22.0006 26.6603C21.7126 26.4437 16.6046 23.197 14.8059 21.6103C14.3019 21.177 13.7259 20.3117 14.8779 19.3017L22.4319 12.087C23.2952 11.2217 24.1586 9.20167 20.5612 11.6543L10.4879 18.5077C10.4879 18.5077 9.33655 19.2297 7.17855 18.5803L2.50122 17.137C2.50122 17.137 0.774553 16.055 3.72455 14.973C10.9199 11.5823 19.7699 8.11967 27.6119 4.873H27.6132Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
              </a>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
