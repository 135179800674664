import { useWeb3Modal } from "@web3modal/wagmi/react";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function Header() {
  const { open } = useWeb3Modal();
  const navigate = useNavigate();
  return (
    <div style={{ marginBottom: "20px" }} class="ln-header">
      <div class="ln-header-left">
        <div class="ln-header-social">
          <a class="is-x" href="#" target="_blank" title="X">
            <div class="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                // xml:space="preserve"
                viewBox="0 0 20 20"
              >
                <path
                  fill="currentColor"
                  d="M15.8.9h3.1l-6.7 7.7L20 19.1h-6.2L9 12.7 3.5 19H.4l7.2-8.2L0 .9h6.3l4.4 5.8L15.8.9zm-1.1 16.3h1.7L5.4 2.7H3.6l11.1 14.5z"
                ></path>
              </svg>
            </div>
          </a>
          <a
            class="is-telegram"
            href="#"
            target="_blank"
            title="Telegram official channel"
          >
            <div class="icon">
              <svg
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M27.6132 4.873C27.6132 4.873 30.2032 3.863 29.9866 6.31567C29.9152 7.32567 29.2679 10.861 28.7639 14.6843L27.0372 26.011C27.0372 26.011 26.8932 27.6703 25.5979 27.959C24.3032 28.247 22.3606 26.949 22.0006 26.6603C21.7126 26.4437 16.6046 23.197 14.8059 21.6103C14.3019 21.177 13.7259 20.3117 14.8779 19.3017L22.4319 12.087C23.2952 11.2217 24.1586 9.20167 20.5612 11.6543L10.4879 18.5077C10.4879 18.5077 9.33655 19.2297 7.17855 18.5803L2.50122 17.137C2.50122 17.137 0.774553 16.055 3.72455 14.973C10.9199 11.5823 19.7699 8.11967 27.6119 4.873H27.6132Z"
                  fill="currentColor"
                ></path>
              </svg>
            </div>
          </a>
          <p>Join us!</p>
        </div>
      </div>
      <div onClick={() => navigate("/")} class="ln-header-center">
        <div class="ln-header-center-logo">
          <picture class="is-token">
            <source
              srcset="assets/img/hamster-coin.webp"
              type="image/webp"
              alt="Hamster Coin"
            />
            <img
              class="img-responsive"
              src="assets/img/hamster-coin.png"
              alt="Hamster Coin"
            />
          </picture>
        </div>
        <p>Hamster Coin</p>
      </div>
      <div class="ln-header-right">
        <button onClick={() => open()} class="button">
          <p>Whitepaper</p>
          <div class="icon is-ln-arrow">
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.583333"
                y="0.583333"
                width="26.8333"
                height="26.8333"
                rx="13.4167"
                stroke="white"
                stroke-width="1.16667"
              ></rect>
              <path
                d="M9.13086 9.73926H18.5656M18.5656 9.73926V18.5653M18.5656 9.73926L9.13086 18.5653"
                stroke="white"
                stroke-width="1.16667"
              ></path>
            </svg>
          </div>
          <span>coming soon</span>
        </button>
      </div>
    </div>
  );
}
