import { BrowserRouter, Route, Routes } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

import { bsc, bscTestnet, mainnet, sepolia } from "viem/chains";
import { WagmiConfig } from "wagmi";
import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
import { Staking } from "./Components/Context/StakingApp";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./Components/Home";

import Header from "./Components/Header";

function App() {
  const projectId = "0345c7230f52356034d20c7c5fbf2519";
  // 2. Create wagmiConfig
  const metadata = {
    name: "InstaMillionaire",
    description:
      "Welcome to InstaMillionaire, It is future of innovation and finance",
    url: "https://instamillionaire.io/",
  };

  const chains = [mainnet, sepolia];
  const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata });

  // 3. Create modal
  createWeb3Modal({ wagmiConfig, projectId, chains });
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <WagmiConfig config={wagmiConfig}>
      <BrowserRouter>
        <Staking>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
          </Routes>
        </Staking>
      </BrowserRouter>
      <ToastContainer />
    </WagmiConfig>
  );
}

export default App;
