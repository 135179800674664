"use Server";
import React, { useState, createContext } from "react";
import { useAccount, useContractRead, useContractWrite } from "wagmi";
import {
  // usdtAddress,
  // usdtAbi1,
  Presealtoken,
  PresealAbi,
  HamsterAddress,
  HamsterAbi,
} from "./constant";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export const StakingApp = createContext();

export const Staking = ({ children }) => {
  const { address } = useAccount();
  const [name, setName] = useState("");
  const [symbol, setSymbol] = useState("");
  const [totalSaply, setTotalSaply] = useState("");
  const [softCap, setSoftCap] = useState("");
  const [time, setTime] = useState(null);
  const [amount, setAmount] = useState();
  const [token, setToken] = useState();
  const [buyamount, setBuyamount] = useState();
  const [buyamount1, setBuyamount1] = useState();
  const [contributer, setContributer] = useState();
  const [usdttoken, setUsdttoken] = useState(0);
  const [imtToken, setImttoken] = useState(0);
  const [value, setValue] = useState(1);
  const [limit, setLimit] = useState();
  const [refaddress, setRefaddress] = useState();
  const navigate = useNavigate();
  useContractRead({
    address: HamsterAddress,
    abi: HamsterAbi,
    functionName: "name",
    // args: [address],
    onSuccess(data) {
      setName(data.toString());
    },

    watch: true,
  });

  useContractRead({
    address: HamsterAddress,
    abi: HamsterAbi,
    functionName: "symbol",
    // args: [address],
    onSuccess(data) {
      setSymbol(data.toString());
    },

    watch: true,
  });
  useContractRead({
    address: HamsterAddress,
    abi: HamsterAbi,
    functionName: "totalSupply",
    // args: [address],
    onSuccess(data) {
      setTotalSaply(Number(data) / 10 ** 18);
    },

    watch: true,
  });
  useContractRead({
    address: Presealtoken,
    abi: PresealAbi,
    functionName: "_totalTokenSale",
    // args: [address],
    onSuccess(data) {
      setSoftCap(Number(data) / 10 ** 18);
    },

    watch: true,
  });

  useContractRead({
    address: Presealtoken,
    abi: PresealAbi,
    functionName: "_endSale",
    // args: [address],
    onSuccess(data) {
      setTime(Number(data) * 1000);
    },

    watch: true,
  });

  useContractRead({
    address: Presealtoken,
    abi: PresealAbi,
    functionName: "getToken",
    args: ["1000000000000000000"],
    onSuccess(data) {
      setToken(Number(data) / 10 ** 18);
    },

    watch: true,
  });
  useContractRead({
    address: Presealtoken,
    abi: PresealAbi,
    functionName: "Limit",
    // args: ["1000000000000000000"],
    onSuccess(data) {
      setLimit(Number(data) / 10 ** 18);
    },

    watch: true,
  });

  useContractRead({
    address: Presealtoken,
    abi: PresealAbi,
    functionName: "purchases",
    args: [address],
    onSuccess(data) {
      setBuyamount(Number(data[0]) / 10 ** 18);
      setBuyamount1(Number(data[1]) / 10 ** 18);
    },

    watch: true,
  });
  useContractRead({
    address: Presealtoken,
    abi: PresealAbi,
    functionName: "_totalContributer",
    // args: [address],
    onSuccess(data) {
      setContributer(Number(data));
    },

    watch: true,
  });

  const { write: buy, isLoading: val2 } = useContractWrite({
    address: Presealtoken,
    abi: PresealAbi,
    functionName: "buyTokens",
    value: `${1 * 10 ** 16 * value}`,
    args: [value],

    onError(data) {
      const dat = data?.toString()?.split("Contract Call");
      toast.warn(`Approve Warning ${dat?.[0]}`);
    },
    onSuccess(data) {
      toast.success(`Approveusd is Successful ${data.hash.toString()}`);
    },
  });

  const { write: buyrefaddress, isLoading: val4 } = useContractWrite({
    address: Presealtoken,
    abi: PresealAbi,
    functionName: "ReferralbuyTokens",
    value: `${1 * 10 ** 16 * value}`,
    args: [value, refaddress],

    onError(data) {
      const dat = data?.toString()?.split("Contract Call");
      toast.warn(`Approve Warning ${dat?.[0]}`);
    },
    onSuccess(data) {
      toast.success(`Approveusd is Successful ${data.hash.toString()}`);
    },
  });

  //swap

  //stakin 1 end
  return (
    <StakingApp.Provider
      value={{
        name,
        symbol,

        HamsterAddress,
        totalSaply,
        softCap,
        time,
        setAmount,
        amount,

        buy,
        token,
        buyamount,
        buyamount1,
        contributer,
        val2,

        usdttoken,
        setUsdttoken,
        imtToken,
        setImttoken,
        limit,
        value,
        setValue,
        buyrefaddress,
        refaddress,
        setRefaddress,
        val4,
      }}
    >
      {children}
    </StakingApp.Provider>
  );
};
